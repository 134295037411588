import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../utils/layout"
import SEO from "../utils/seo"

import Button from "@atoms/button"
import { Section, Container, Content } from "@atoms/layout"
import { Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query fetch404 {
      file(relativePath: { eq: "visuals/404/404.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 960
            quality: 80
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Section sm mdTop data-datocms-noindex>
        <Container aqua>
          <Section smTop>
            <Container containerSmall>
              <Image>
                <GatsbyImage
                  image={data.file.childImageSharp.gatsbyImageData}
                  alt="Floating papercraft woman with laptop and giant 404 type"
                />
              </Image>
              <Content paragraph>
                <Paragraph level="lg" center>
                  Looks like you’ve found a dead end. We couldn't find the page
                  you’re looking for.
                </Paragraph>
                <Button to="/" primary solid center>
                  Home
                </Button>
              </Content>
            </Container>
          </Section>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage

const Image = styled.div`
  ${media.sm`
    transform: translateY(5%);
  `}
`
